import HeroSection from "../components/homepage/HeroSection";
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <div>
            <div>
                {/* <h1>Homepage</h1> */}
                <HeroSection />
            </div>
            <div>
                <button>
                    <Link to="https://www.google.com" target="_blank" style={{ 'color': 'white' }}>
                        Go to Google
                    </Link>
                </button>
            </div>
        </div>
    )
};

export default Home;