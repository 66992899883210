import React from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';



const TabbedContainer = ({ tabs, handleTabChange, activeTab }) => {
    return (
        <div>
            {/* <Button variant="contained" onClick={handleAddTab}>Add Tab</Button> */}
            <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.title} />
                ))}
            </Tabs>
            {tabs.map((tab, index) => (
                <TabPanel key={index} value={activeTab} index={index}>
                    <Typography>{tab.content}</Typography>
                </TabPanel>
            ))}
        </div>
    );
};



const TabPanel = ({ children, value, index }) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
};

//export default TabbedContainer;

export { TabbedContainer };