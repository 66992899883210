import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import Navbar from '../components/Navbar';
import { Button as ButtonMUI } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { InputLabel, FormControl } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import invokeLambdaFunction from '../lambda/lambdaFunctions';
import { TabbedContainer } from '../components/TabbedContainer';
import ToothScheme from '../components/ToothScheme';

import { Heading, Text, useTheme, View, Image, Button, useAuthenticator } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
I18n.putVocabularies(translations);
I18n.setLanguage('de');
I18n.putVocabularies({
    de: {
        'Enter your Email': 'Geben Sie Ihre Email ein',
    }
});

function b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

const formFields = {
    confirmVerifyUser: {
        confirmation_code: {
            label: 'New Label',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
        },
    },
};

const components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Image
                    alt="Zabapp logo"
                    src="https://kvdoc-images.s3.amazonaws.com/tooth_drawing.png"
                    height="250px"
                />
            </View>
        );
    },

    Footer() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    &copy; All Rights Reserved
                </Text>
            </View>
        );
    },

    SignIn: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Anmelden
                </Heading>
            );
        },
        Footer() {
            const { toResetPassword } = useAuthenticator();

            return (
                <View textAlign="center">
                    <Button
                        fontWeight="normal"
                        onClick={toResetPassword}
                        size="small"
                        variation="link"
                    >
                        Passwort zurücksetzen
                    </Button>
                </View>
            );
        },
    },

    ResetPassword: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Passwort zurücksetzen
                </Heading>
            );
        },
    },

    VerifyUser: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <Text>Footer Information</Text>;
        },
    },

    ConfirmVerifyUser: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <Text>Footer Information</Text>;
        },
    },
};

const Kvdoc = () => {
    const [responseText, setResponseText] = useState([]);
    const [responseTreatment, setResponseTreatment] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [executionTime, setExecutionTime] = useState(0);

    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    const handleAddTab = ({ tabTitle }) => {
        const newTabs = [...tabs, { title: tabTitle, content: `Content ${tabs.length + 1}` }];
        setTabs(newTabs);
        setActiveTab(newTabs.length - 1);
    };

    const treatments = {
        "pluginType": "State",
        "id": "treatments",
        "persistedValueKey": "treatments",
        "persistValue": false,
        "value": [
            {
                "code": "Füllung",
                "optimize_price": false,
                "tooth_id": "tooth_id",
                "tooth_code": "15",
                "app_number": 1,
                "session": 1,
                "price": null,
                "type": {
                    "private": false,
                    "public": false,
                    "public_plus": true
                },
                "surface": {
                    "occlusal": true,
                    "inzisal": false,
                    "mesial": false,
                    "distal": false,
                    "lingual": false,
                    "vestibular": false,
                    "zervikal": false
                },
                "adhesive": true,
                "vital": false,
                "p": false,
                "cp": false,
                "rubber_dam": true,
                "xray": 0,
                "fluoridated": false,
                "coverings": false,
                "microscope": false,
                "kv": {
                    "kv_mode": false
                }
            }
        ]
    }

    const session = {
        "pluginType": "State",
        "id": "session",
        "persistedValueKey": "session",
        "persistValue": false,
        "value": {
            "bema": true,
            "pregnant": false,
            "patient_age": {
                "below 4": false,
                "below 6": false,
                "below 16": false,
                "below 18": false,
                "above 18": true
            },
            "treatments": [],
            "kv_mode": false
        }
    }

    const current_user = {
        "email": "michaelhopf@michael-hopf-consulting.com",
        "lastName": "Hopf",
        "profilePhotoUrl": null,
        "sid": "user_a2a1a884e7cf414c891e9ec865ba869e",
        "metadata": {},
        "groups": [
            {
                "id": 1449430,
                "name": "admin",
                "createdAt": "2023-02-20T10:50:41.744Z",
                "updatedAt": "2023-02-20T10:50:41.744Z"
            },
            {
                "id": 1449433,
                "name": "All Users",
                "createdAt": "2023-02-20T10:50:41.744Z",
                "updatedAt": "2023-02-20T10:50:41.744Z"
            }
        ],
        "externalIdentifier": null,
        "id": 538408,
        "firstName": "Michael",
        "fullName": "Michael Hopf"
    }

    const fillingbody = b64EncodeUnicode(JSON.stringify({ 'treatments': treatments, 'session': session, 'current_user': current_user }));


    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleClick = async () => {
        const payload = {
            "resource": "/{proxy+}",
            "path": "/get_teeth/",
            "httpMethod": "GET",
            "requestContext": {}
        };
        const lambdaResponse = await invokeLambdaFunction('kvdoc-dev-1-Lambda-xpVAKMUjUKG0', payload);
        const output = JSON.parse(lambdaResponse.Payload)
        if (typeof output.body === 'string') {
            const parsedBody = JSON.parse(output.body);
            setResponseText(parsedBody);
        } else {
            setResponseText(output.body || []);
        }
        console.log("called get teeth")
    };

    const getTreatment = async (body) => {
        const start = performance.now();
        console.log("called getTreatment")
        const payload = {
            "body": body,
            "resource": "/{proxy+}",
            "path": "/treatment_performances/",
            "httpMethod": "POST",
            "isBase64Encoded": true,
            "pathParameters": {
                "proxy": "/treatment_performances/"
            },
            "requestContext": {
                "path": "/treatment_performances/",
                "resourcePath": "/{proxy+}",
                "httpMethod": "POST"
            }
        };
        const lambdaResponse = await invokeLambdaFunction('kvdoc-dev-1-Lambda-xpVAKMUjUKG0', payload);
        const responseTime = performance.now() - start;
        const output = JSON.parse(lambdaResponse.Payload)
        if (typeof output.body === 'string') {
            const parsedBody = JSON.parse(output.body);
            setResponseTreatment(parsedBody.performances.copy_strings.z1_string_no_docu);
        } else {
            setResponseTreatment(output.body || []);
        }
        setExecutionTime(responseTime);
    };

    return (
        <Authenticator formFields={formFields} components={components} hideSignUp={true}>
            {({ signOut }) => (
                < div style={{ maxWidth: '1150px', margin: '0 auto' }}>
                    <Navbar></Navbar>
                    <h1>
                        Hello, welcome to kvdoc!
                    </h1>
                    <h3>You are authenticated. This is the premium content.</h3>
                    <div style={{ textAlign: 'center' }}>
                        <label>
                            Enter treatment:
                        </label>
                        <textarea
                            name="postContent"
                            rows={4}
                            cols={40}
                            value={responseText}
                            onChange={(e) => setResponseText(e.target.value)}
                        />
                        <div>
                            <ButtonMUI onClick={handleClick} variant="contained">Get Teeth</ButtonMUI>
                        </div>
                    </div>
                    <FormControl fullWidth>
                        <InputLabel id="select-tooth">Zahn auswählen</InputLabel>
                        <Select
                            value={selectedValue}
                            label="Zahn auswählen"
                            onChange={handleDropdownChange}
                            style={{ width: '200px', textAlign: 'center' }}
                            inputProps={{
                                style: { textAlign: 'center' },
                            }}
                        >
                            {responseText.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <label>
                        Filling result:
                    </label>
                    <div style={{ textAlign: 'center' }}>
                        <textarea
                            name="outputFilling"
                            rows={4}
                            cols={40}
                            value={responseTreatment}
                            onChange={(e) => setResponseTreatment(e.target.value)}
                        />
                        <div>
                            <ButtonMUI onClick={() => getTreatment(fillingbody)} variant="contained">Get Filling result</ButtonMUI>
                        </div>
                        <p>Execution time: {executionTime.toFixed(2)} milliseconds</p>
                    </div>
                    <div>
                        <Box
                            borderRadius={8}
                            boxShadow={3}
                            bgcolor="background.paper"
                            p={3}
                            marginTop='20px'
                        >
                            <div textAlign='left'>
                                Zähne auswählen
                            </div>
                            <ToothScheme handleAddTab={handleAddTab} />
                        </Box>
                    </div>
                    <Box
                        marginTop='30px'
                        borderRadius={8}
                        boxShadow={3}
                        bgcolor="background.paper"
                        p={3}
                    >
                        <div style={{
                            marginTop: '30px',
                            border: '2px solid lightblue',
                            background: 'white',
                            padding: '20px',
                            maxWidth: '1200px',
                            overflowX: 'auto'
                        }}>
                            <TabbedContainer tabs={tabs} handleTabChange={handleTabChange} activeTab={activeTab} />
                        </div>
                    </Box>
                    <button onClick={signOut}>Sign Out</button>
                </div>
            )
            }
        </Authenticator >
    );
};

export default Kvdoc;