import React from 'react';
import PricingOption from './PricingOption';

const ProductHomepage = () => {
    return (
        <div>
            {/* Upper menu */}
            <nav>
                {/* Logo */}
                <div>Logo</div>
                {/* Navigation menu */}
                <ul>
                    <li>Home</li>
                    <li>Pricing</li>
                    <li>About</li>
                    <li>Login</li>
                </ul>
            </nav>

            {/* Main slogan */}
            <section style={{ backgroundColor: 'blue', color: 'white' }}>
                <h1>Main Slogan</h1>
                <p>...</p>
            </section>

            {/* Pricing options */}
            <section>
                <h2>Pricing Options</h2>
                <div className="pricing-options-container">
                    <PricingOption
                        title="Basic"
                        price="$9.99/month"
                        features={['Feature 1', 'Feature 2', 'Feature 3']}
                    />
                    <PricingOption
                        title="Standard"
                        price="$19.99/month"
                        features={['Feature 1', 'Feature 2', 'Feature 3']}
                    />
                    <PricingOption
                        title="Premium"
                        price="$29.99/month"
                        features={['Feature 1', 'Feature 2', 'Feature 3']}
                    />
                </div>
            </section>

            {/* Advantages */}
            <section style={{ backgroundColor: 'blue', color: 'white' }}>
                <h2>Advantages</h2>
                {/* Advantage cards */}
            </section>

            {/* Footer */}
            <footer style={{ backgroundColor: 'blue', color: 'white' }}>
                {/* Footer content */}
            </footer>
        </div>
    );
};

export default ProductHomepage;