import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Kvdoc from './routes/kvdoc';
import Expenses from './routes/expenses';
import Invoices from './routes/invoices';
import Home from './routes/home';
import PriceTable from "./components/payment/PriceTable";
import MuiNavbar from '../src/components/MuiNavbar';
import OldPayment from './routes/oldpayment';
import ProductHomepage from './pages/ProductHomepage';

function App() {
  return (
    <div className='App'>
      <Router>

        <div className='content'>
          <MuiNavbar></MuiNavbar>
          {/* <NavLink className='content' exact activeClassName="active" to="./">Home</NavLink>
          <NavLink className='content' activeClassName="active" to="./expenses">Produkt</NavLink>
          <NavLink className='content' activeClassName="active" to="./invoices">Preis</NavLink>
          <NavLink className='content' activeClassName="active" to="./kvdoc">Login</NavLink> */}
          {/* <Subscribe /> */}
        </div>

        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/expenses" element={<Expenses />}></Route>
          <Route path="/homepage" element={<ProductHomepage />}></Route>
          <Route path="/invoices" element={<Invoices />}></Route>
          <Route path="/kvdoc" element={<Kvdoc />}></Route>
          <Route path="/pricing" element={<PriceTable />}></Route>
          <Route path="/oldpayment" element={<OldPayment />}></Route>
        </Routes>

      </Router>

    </div>
  );
}

export default App;


// import "./App.css";
// import Payment from "./components/payment/Payment";
// import Completion from "./components/payment/Completion";

// import { BrowserRouter, Routes, Route } from "react-router-dom";

// function App() {
//   return (
//     <main>
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Payment />} />
//           <Route path="/completion" element={<Completion />} />
//         </Routes>
//       </BrowserRouter>
//     </main>
//   );
// }

// export default App;