import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [
    {
        title: 'Home',
        path: '/',
        icon: <AiIcons.AiFillHome />,
        cName: 'nav-text'
    },
    {
        title: 'Behandlungen',
        path: '/reports',
        icon: <AiIcons.AiFillAppstore />,
        cName: 'nav-text'
    },
    {
        title: 'HKP erstellen',
        path: '/team',
        icon: <AiIcons.AiFillContainer />,
        cName: 'nav-text'
    },
    {
        title: 'Einstellungen',
        path: '/products',
        icon: <AiIcons.AiFillSetting />,
        cName: 'nav-text'
    },
    {
        title: 'Support',
        path: '/support',
        icon: <IoIcons.IoMdHelpCircle />,
        cName: 'nav-text'
    }
];