import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';

const ToothScheme = ({ handleAddTab }) => {
    const [selectedTeeth, setSelectedTeeth] = useState([]);
    //const upperTeeth = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28];
    //const lowerTeeth = [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38];

    const upperTeeth = useMemo(() => {
        return [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28];
    }, []);
    const lowerTeeth = useMemo(() => {
        return [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38];
    }, []);

    const theme = useTheme();
    const standardColor = theme.palette.primary.main;

    const [toothImages, setToothImages] = useState([]);

    useEffect(() => {
        const loadToothImages = async () => {
            const images = {};
            const allTeeth = upperTeeth.concat(lowerTeeth);
            for (const i of allTeeth) {
                const imageModule = await import(`../assets/images/tooth/tooth_${i}.PNG`);
                const imageSrc = imageModule.default;
                images[i] = imageSrc;
            }
            setToothImages(images);
        };

        loadToothImages();
    }, [lowerTeeth, upperTeeth]);

    const handleToothClick = (toothNumber, handleAddTab) => {
        handleAddTab({ tabTitle: `Füllung ${toothNumber}` });
        if (selectedTeeth.includes(toothNumber)) {
            // Deselect the tooth if it's already selected
            setSelectedTeeth(selectedTeeth.filter((tooth) => tooth !== toothNumber));
        } else {
            // Select the tooth if it's not selected
            setSelectedTeeth([...selectedTeeth, toothNumber]);
        }
    };

    //const toothImage = require(`../assets/images/tooth/tooth_18.PNG`).default;
    return (
        <div>
            <div>
                <div style={{ display: 'flex' }}>
                    {upperTeeth.map(toothNumber => {
                        const isSelected = selectedTeeth.includes(toothNumber);
                        const toothImage = toothImages[toothNumber]
                        return (
                            <Button
                                key={toothNumber}
                                variant="outlined"
                                onClick={() => handleToothClick(toothNumber, handleAddTab)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginRight: '4px',
                                    borderColor: isSelected ? standardColor : '#bdbdbd',
                                    width: '100px', // Adjust the width as needed
                                    height: '135px', // Adjust the height as needed
                                    border: isSelected ? '2px solid' : '0.5px #bdbdbd solid'
                                }}
                            >
                                <img
                                    src={toothImage} // Replace with the actual path to your image files
                                    alt={`Upper Tooth ${toothNumber}`}
                                    style={{ width: '100%', height: 'auto' }}
                                />
                                <span style={{ fontWeight: 'bold' }}>{toothNumber}</span>
                            </Button>
                        );
                    })}
                </div>
            </div>
            <div>
                <div style={{ display: 'flex', marginTop: '5px' }} >
                    {lowerTeeth.map(toothNumber => {
                        const isSelected = selectedTeeth.includes(toothNumber);
                        const toothImage = toothImages[toothNumber]
                        return (
                            <Button
                                key={toothNumber}
                                variant="outlined"
                                onClick={() => handleToothClick(toothNumber, handleAddTab)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginRight: '4px',
                                    borderColor: isSelected ? standardColor : '#bdbdbd',
                                    border: isSelected ? '2px solid' : '0.5px #bdbdbd solid'
                                }}
                            >
                                <img
                                    src={toothImage} // Replace with the actual path to your image files
                                    alt={`Lower Tooth ${toothNumber}`}
                                    style={{ width: '100%', height: 'auto' }}
                                />
                                <span style={{ fontWeight: 'bold' }}>{toothNumber}</span>
                            </Button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ToothScheme;


