const PricingOption = ({ title, price, features }) => {
    return (
        <div>
            <h3>{title}</h3>
            <p>{price}</p>
            <ul>
                {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
        </div>
    );
};

export default PricingOption;