import { loadStripe } from "@stripe/stripe-js";
import Button from '@mui/material/Button';

export default function OldPayment() {
    const handleClick = async e => {
        const stripe = await loadStripe('pk_test_51NH5cRJSVnHHfaRacpJsaFuYsD0A1G49pcj6b8HyZ8yZVtVa0XqTIr1vALQhHjCgS3sNCsyxy1CZdzW54xtcrnqK00cHdIyi7O')
        await stripe.redirectToCheckout({
            lineItems: [{
                price: 'price_1NH63RJSVnHHfaRaUsnPDDRL',
                quantity: 1
            }],
            mode: 'subscription',
            successUrl: 'http://localhost:4242/kvdoc',
            cancelUrl: 'http://localhost:4242/cancel',
        })
    }
    return (
        <main style={{ padding: "1rem 0" }}>
            <h2>Old Payment</h2>
            <Button variant="contained" onClick={handleClick} > Zabapp abonieren</Button >
        </main>
    );
}