import AWS from 'aws-sdk';

AWS.config.update({
    region: 'us-east-1',
    accessKeyId: process.env['REACT_APP_AWS_SECRET_KEY'],
    secretAccessKey: process.env['REACT_APP_AWS_ACCESS_KEY'],
});

// export default function invokeLambdaFunction(functionName, payload) {
//     const lambda = new AWS.Lambda();
//     const params = {
//         FunctionName: functionName,
//         Payload: JSON.stringify(payload),
//     };
//     return lambda.invoke(params).promise();
// }

// export default function TestMichael() {
//     return (
//         <main style={{ padding: "1rem 0" }}>
//             <h2>Preis</h2>
//         </main>
//     );
// }

const invokeLambdaFunction = (functionName, payload) => {
    const lambda = new AWS.Lambda();
    const params = {
        FunctionName: functionName,
        Payload: JSON.stringify(payload),
    };
    return lambda.invoke(params).promise();
};

export default invokeLambdaFunction;