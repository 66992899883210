import * as React from 'react';

function PriceTable() {
    return (
        <stripe-pricing-table pricing-table-id="prctbl_1NIZ2GJSVnHHfaRaj1oyF4CZ"
            publishable-key="pk_test_51NH5cRJSVnHHfaRacpJsaFuYsD0A1G49pcj6b8HyZ8yZVtVa0XqTIr1vALQhHjCgS3sNCsyxy1CZdzW54xtcrnqK00cHdIyi7O">
        </stripe-pricing-table>
    )
}

export default PriceTable;